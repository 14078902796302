$primary: #be1e2d;

@import '~bootstrap/scss/bootstrap.scss';

.jumbotron {
	overflow: scroll;
	margin-bottom: 0;
	height: 100%;
}

a {
	color: #3880ff;
}

.resend-span {
	color: #3880ff;
	margin-left: 10px;
	cursor: pointer;
}

.Logo {
	background-image: url(./favicon.ico);
	background-repeat: no-repeat;
	background-size: contain;
	margin: auto;
	height: 5rem;
	width: 5rem;
	margin-bottom: 2rem;
}

ion-header {
	border-bottom: 3px solid #be1e2d;
}

label {
	margin-bottom: 0;
}
